.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



/****************************/
/******App Heager **********/
/****************************/

.app-header{
  width: 100%;
  border: solid 1px #000;
  display: flex;
  justify-content: space-between;
  background-color: #146DA4;
  
}

.user-info header.greetings{
  border-color: red;
}

.navigation-menu li{
  list-style: none;
  display: inline;
  margin: 5em;
}

.navigation-menu li a{
  text-decoration: none;
  font-size: 2.5em;
  font-weight: 700;
  color: white;
}



/**************************/
/********** HOME *********/
/*************************/
 
.home{
   width: 60%;
   margin-left: 20%;
   margin-right: 20%;
   margin-top: 5px;
   margin-bottom: 15px;
   border: 0 1px 1px 1px;
   border: 1px solid gray;
   padding: 20px;
   border-radius: 30px;
   box-shadow: 0 5px 10px 0 gray;
 }

 .home p{
   margin-left: 25%;
   margin-right: 25%;
  
 }
  
 .home h2{
  border-bottom: 1px solid #CCD9E0;
 padding-bottom: 5px;
  font-size: 2em;
 }
  .home img{
    max-width: 100%;
} 

.form-group{
  margin-top: 15px;
}